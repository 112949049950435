import React, { createContext, useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { getMsalConfig } from '../authConfig';
import { setAccessToken, getAccessToken, setUserRoles, getUserRoles } from '../tokenStorage';

interface AuthContextProps {
    isAuthenticated: boolean;
    user: AccountInfo | null;
    accessToken: string | null;
    roles: string[] | null;
    login: () => Promise<void>;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
    isAuthenticated: false,
    user: null,
    accessToken: null,
    roles: null,
    login: async () => {},
    logout: () => {},
});

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const { instance, accounts } = useMsal();
    const [user, setUser] = useState<AccountInfo | null>(null);
    const [accessToken, setAccessTokenState] = useState<string | null>(getAccessToken());
    const [roles, setRoles] = useState<string[] | null>(getUserRoles());
    const [loginRequest, setLoginRequest] = useState<{ scopes: string[] } | null>(null);

    useEffect(() => {
        const fetchLoginRequest = async () => {
            try {
                const { loginRequest } = await getMsalConfig();
                setLoginRequest(loginRequest);
            } catch (error) {
                console.error('Failed to fetch loginRequest:', error);
            }
        };
        fetchLoginRequest();
    }, []);

    const login = async () => {
        if (!loginRequest) {
            console.error('Login request is not available.');
            return;
        }

        try {
            await instance.loginPopup(loginRequest);
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const logout = () => {
        instance.logoutPopup().catch((e) => console.error('Logout failed:', e));
        setUser(null);
        setAccessToken(null);
        setUserRoles(null);
        setRoles(null);
    };

    const requestAccessToken = async (account: AccountInfo) => {
        if (!loginRequest) return;

        const request = {
            scopes: loginRequest.scopes,
            account,
        };

        try {
            const response: AuthenticationResult = await instance.acquireTokenSilent(request);
            if (response.accessToken) {
                const tokenRoles = response.account.idTokenClaims?.roles || [];
                setAccessToken(response.accessToken);
                setUserRoles(tokenRoles);
                setAccessTokenState(response.accessToken);
                setRoles(tokenRoles);
            }
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                try {
                    const response: AuthenticationResult = await instance.acquireTokenPopup(request);
                    if (response.accessToken) {
                        const tokenRoles = response.account.idTokenClaims?.roles || [];
                        setAccessToken(response.accessToken);
                        setUserRoles(tokenRoles);
                        setAccessTokenState(response.accessToken);
                        setRoles(tokenRoles);
                    }
                } catch (popupError) {
                    console.error('Popup token acquisition failed:', popupError);
                }
            } else {
                console.error('Token acquisition failed:', error);
            }
        }
    };

    useEffect(() => {
        if (accounts.length > 0) {
            const account = accounts[0];
            setUser(account);
            requestAccessToken(account);
        } else {
            setUser(null);
            setAccessTokenState(null);
            setRoles(null);
            setAccessToken(null);
            setUserRoles(null);
        }
    }, [accounts, instance, loginRequest]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (user && accessToken) {
                requestAccessToken(user);
            }
        }, 5 * 60 * 1000); // Refresh token every 5 minutes

        return () => clearInterval(intervalId); // Clean up interval
    }, [user, accessToken]);

    return (
        <AuthContext.Provider
            value={{ isAuthenticated: !!user, user, accessToken, roles, login, logout }}
        >
            {children}
        </AuthContext.Provider>
    );
};