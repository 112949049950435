// components/common/Header.tsx
import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import UserMenu from './UserMenu';
import NotificationMenu from './NotificationsMenu';

const { Header } = Layout;

const AppHeader: React.FC = () => {
    const { isAuthenticated } = useAuth();

    return (
        <Header className="header">
            <div className="logo" />
            <Menu theme="dark" mode="horizontal" selectable={false}>
                <Menu.Item key="home">
                    <Link to="/">Home</Link>
                </Menu.Item>
                {isAuthenticated && (
                    <Menu.Item key="dashboard">
                        <Link to="/dashboard">Dashboard</Link>
                    </Menu.Item>
                )}
            </Menu>
            <div style={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                {isAuthenticated && <NotificationMenu />}
                <UserMenu />
            </div>
        </Header>
    );
};

export default AppHeader;