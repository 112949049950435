// pages/ProfilePage.tsx
import React from 'react';
import MainLayout from '../layouts/MainLayout';

const ProfilePage: React.FC = () => (
    <MainLayout>
        <h1>Profile</h1>
        {/* Profile content */}
    </MainLayout>
);

export default ProfilePage;