let accessToken: string | null = sessionStorage.getItem('accessToken');
let userRoles: string[] | null = sessionStorage.getItem('userRoles') ? JSON.parse(sessionStorage.getItem('userRoles')!) : null;

export const setAccessToken = (token: string | null) => {
    accessToken = token;
    if (token) {
        sessionStorage.setItem('accessToken', token);
    } else {
        sessionStorage.removeItem('accessToken');
    }
};

export const getAccessToken = () => accessToken;

export const setUserRoles = (roles: string[] | null) => {
    userRoles = roles;
    if (roles) {
        sessionStorage.setItem('userRoles', JSON.stringify(roles));
    } else {
        sessionStorage.removeItem('userRoles');
    }
};

export const getUserRoles = () => userRoles;