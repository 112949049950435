// components/common/LoadingSpinner.tsx
import React from 'react';
import { Spin } from 'antd';

const LoadingSpinner: React.FC = () => (
    <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
    </div>
);

export default LoadingSpinner;