// src/App.tsx

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { LoadingProvider } from './context/LoadingContext';
import AppRoutes from './routes/AppRoutes';
import ErrorBoundary from './components/common/ErrorBoundary';

const App: React.FC = () => (
    <Router>
        <AuthProvider>
            <LoadingProvider>
                <ErrorBoundary>
                    <AppRoutes />
                </ErrorBoundary>
            </LoadingProvider>
        </AuthProvider>
    </Router>
);

export default App;