import React, { useState, useEffect } from 'react';
import GraphLayout from '../layouts/GraphLayout';
import GraphComponent from '../components/common/GraphComponent';
import { getGraphFull } from '../services/api/graph';

interface Node {
    id: string | number;
    [key: string]: any;
}

interface Link {
    id?: string | number;
    source: string | number;
    target: string | number;
    [key: string]: any;
}

interface GraphData {
    nodes: Node[];
    links: Link[];
}

interface GraphResponse {
    data: GraphData;
    message?: string;
}

const ComplexGraphPage: React.FC = () => {
    const [graphData, setGraphData] = useState<GraphResponse>({
        data: {
            nodes: [],
            links: [],
        },
        message: undefined,
    });
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchGraphData = async () => {
            try {
                setLoading(true);
                const response = await getGraphFull();
                setGraphData(response.data); // Assumes response contains the expected structure
            } catch (err: any) {
                console.error('Error fetching graph data:', err);
                setError('Failed to load graph data.');
            } finally {
                setLoading(false);
            }
        };

        fetchGraphData();
    }, []);

    return (
        <GraphLayout>
            {loading ? (
                <div>Loading graph...</div>
            ) : error ? (
                <div>Error: {error}</div>
            ) : (
                <GraphComponent data={graphData} />
            )}
        </GraphLayout>
    );
};

export default ComplexGraphPage;