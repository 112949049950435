// components/common/Footer.tsx
import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const AppFooter: React.FC = () => (
    <Footer style={{ textAlign: 'center' }}>
        © {new Date().getFullYear()} My App. All Rights Reserved.
    </Footer>
);

export default AppFooter;