// src/services/api/graph.ts

import apiClient from '../apiClient';

export const getGraphFull = async () => {
    return apiClient.get('/api/graph/full');
};

export const getGraphDataQuery = async (query: string, params: any) => {
    return apiClient.post('/api/graph/query', params, { params: { query } });
};

export const getUserGraph = async (userID: string) => {
    return apiClient.get(`/api/graph/user/${userID}`);
};

export const getGroupGraph = async (groupID: string) => {
    return apiClient.get(`/api/graph/group/${groupID}`);
};

export const getComplexGraph = async () => {
    return apiClient.get('/api/graph/complex');
};

// ... other API functions

export const getRelationshipsForNode = async (nodeType: string, nodeID: string) => {
    return apiClient.get(`/api/graph/node/${nodeType}/${nodeID}`);
};