// pages/DashboardPage.tsx
import React, {useContext, useEffect} from 'react';
import { Layout } from 'antd';
import MainLayout from '../layouts/MainLayout';
import SideMenu from '../components/common/SideMenu';
import {LoadingContext} from "../context/LoadingContext";

const { Content, Sider } = Layout;

const DashboardPage: React.FC = () => {
    const { setLoading } = useContext(LoadingContext);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Simulate data fetching
                await new Promise((resolve) => setTimeout(resolve, 2000));
                // Replace with actual API call
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [setLoading]);
    return (
        <MainLayout>
            <Layout>
                <Sider width={200} className="site-layout-background">
                    <SideMenu/>
                </Sider>
                <Layout style={{padding: '0 24px 24px'}}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <h1>Dashboard</h1>
                        {/* Dashboard content */}
                    </Content>
                </Layout>
            </Layout>
        </MainLayout>
    );
};
export default DashboardPage;