// pages/NotFoundPage.tsx
import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/');
    };

    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            <h1>404 - Not Found</h1>
            <Button type="primary" onClick={goHome}>
                Go Home
            </Button>
        </div>
    );
};

export default NotFoundPage;