// layouts/AuthLayout.tsx
import React from 'react';
import { Layout } from 'antd';
import AppFooter from '../components/common/Footer';
import AppHeader from '../components/common/Header';

const { Content } = Layout;

interface AuthLayoutProps {
    children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => (
    <Layout>
        <AppHeader />
        <Content style={{ padding: '24px', marginTop: 64 }}>{children}</Content>
        <AppFooter />
    </Layout>
);

export default AuthLayout;