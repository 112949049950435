// components/common/SideMenu.tsx
import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const SideMenu: React.FC = () => {
    const location = useLocation();
    const selectedKey = location.pathname;

    return (
        <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            style={{ height: '100%', borderRight: 0 }}
        >
            <Menu.Item key="/overview">
                <Link to="/overview">Overview</Link>
            </Menu.Item>
            <Menu.Item key="/profile">
                <Link to="/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item key="/complex-graph">
                <Link to="/complex-graph">Complex Graph</Link>
            </Menu.Item>
            <Menu.Item key="/settings">
                <Link to="/settings">Settings</Link>
            </Menu.Item>
        </Menu>
    );
};

export default SideMenu;