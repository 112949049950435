import React from 'react';
import { Menu, Dropdown, Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../hooks/useAuth';
import { useMsal } from '@azure/msal-react';
import { getMsalConfig } from '../../authConfig';

const UserMenu: React.FC = () => {
    const { isAuthenticated, user } = useAuth();
    const { instance } = useMsal();

    const handleLogin = async () => {
        try {
            const { loginRequest } = await getMsalConfig();
            await instance.loginPopup(loginRequest);
        } catch (e) {
            console.error('Login failed:', e);
        }
    };

    const handleLogout = () => {
        instance.logoutPopup().catch((e) => console.error('Logout failed:', e));
    };

    const menu = (
        <Menu>
            <Menu.Item key="logout" onClick={handleLogout}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return isAuthenticated ? (
        <Dropdown overlay={menu} placement="bottomRight">
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Avatar icon={<UserOutlined />} />
                <span style={{ marginLeft: 8 }}>{user?.name || 'User'}</span>
            </div>
        </Dropdown>
    ) : (
        <Button type="primary" onClick={handleLogin}>
            Login
        </Button>
    );
};

export default UserMenu;