import axios from 'axios';
import { getAccessToken } from '../tokenStorage';

const baseURL = process.env.API_BASE_URL || "https://api.aohwv.dev"; // Placeholder URL

console.log('Base URL:', baseURL);

const apiClient = axios.create({
    baseURL,
    timeout: 10000,
});

// Add a request interceptor to include the token and log request details
apiClient.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        // Correctly set the token in the headers
        if (token) {
            config.headers = config.headers || {};
            config.headers.Authorization = `Bearer ${token}`; // Use the actual token
        }

        // Log the outgoing request (excluding sensitive data)
        console.log('API Request:', {
            headers: config.headers,
            method: config.method,
            url: `${config.baseURL ?? ''}${config.url ?? ''}`, // Handle undefined baseURL or url
        });

        return config;
    },
    (error) => {
        console.error('API Request Error:', error.message); // Removed detailed error info
        return Promise.reject(error);
    }
);

// Add a response interceptor to log responses and errors
apiClient.interceptors.response.use(
    (response) => {
        // Log the response details (excluding sensitive data)
        console.log('API Response:', {
            status: response.status,
            statusText: response.statusText,
            config: {
                method: response.config.method,
                url: `${response.config.baseURL ?? ''}${response.config.url ?? ''}`, // Handle undefined baseURL or url
            },
        });

        return response;
    },
    (error) => {
        if (error.response) {
            // Log the error response details (excluding sensitive data)
            console.error('API Response Error:', {
                status: error.response.status,
                statusText: error.response.statusText,
                config: {
                    method: error.response.config.method,
                    url: `${error.response.config.baseURL ?? ''}${error.response.config.url ?? ''}`, // Handle undefined baseURL or url
                },
            });
        } else if (error.request) {
            console.error('API Request Error: No response received');
        } else {
            console.error('API Request Error:', error.message);
        }

        return Promise.reject(error);
    }
);

export default apiClient;
