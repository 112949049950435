import { AppConfigurationClient } from '@azure/app-configuration';
import { handleError } from './utils/handleError';

const connectionString = process.env.REACT_APP_AZURE_APP_CONFIG_CONNECTION_STRING;

if (!connectionString) {
    throw new Error('Azure App Configuration connection string is not set.');
}

export const appConfigClient = new AppConfigurationClient(connectionString);

export const getMsalConfig = async () => {
    try {
        const clientIdSetting = await appConfigClient.getConfigurationSetting({ key: 'clientIdFrontEnd' });
        const tenantIdSetting = await appConfigClient.getConfigurationSetting({ key: 'tenantId' });
        const redirectUriSetting = await appConfigClient.getConfigurationSetting({ key: 'redirectUri' });

        const clientId = clientIdSetting.value || '';
        const msalConfig = {
            auth: {
                clientId,
                authority: `https://login.microsoftonline.com/${tenantIdSetting.value || ''}`,
                redirectUri: redirectUriSetting.value || '',
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: false,
            },
        };

        const loginRequest = {
            scopes: [`api://${clientId}/access_as_user`],
        };

        return { msalConfig, loginRequest };
    } catch (error) {
        const errorMessage = handleError(error, 'Error fetching configuration from Azure App Configuration');
        throw new Error(errorMessage);
    }
};