import React, { useRef, useEffect } from "react";
import ForceGraph2D from "react-force-graph-2d";

interface Node {
    id: string | number;
    [key: string]: any;
}

interface Link {
    id?: string | number;
    source: string | number;
    target: string | number;
    [key: string]: any;
}

interface GraphData {
    nodes: Node[];
    links: Link[];
}

interface GraphProps {
    data: {
        data: {
            nodes: Node[];
            links: Link[];
        };
        message?: string;
    };
}

const GraphComponent: React.FC<GraphProps> = ({ data }) => {
    const graphRef = useRef<any>(null);

    // Destructure nodes and links with defaults
    const { nodes = [], links = [] } = data.data || {};

    // Transform data to ensure compatibility with react-force-graph
    const graphData: GraphData = {
        nodes: nodes.map((node) => {
            const nodeId = node.id ?? node.props?.id;
            if (!nodeId) {
                console.error('Node missing id:', node);
                // Instead of throwing an error, you can choose to skip this node or assign a temporary ID
                // For demonstration, we'll skip the node
                return null;
            }
            return {
                ...node,
                id: nodeId,
            };
        }).filter((node): node is Node => node !== null), // Remove any null entries
        links: links.map((link) => {
            const sourceId = link.startId ?? link.source;
            const targetId = link.endId ?? link.target;

            if (!sourceId || !targetId) {
                console.error('Link missing source or target:', link);
                // Similarly, skip the link or assign default values
                return null;
            }

            return {
                ...link,
                source: sourceId,
                target: targetId,
            };
        }).filter((link): link is Link => link !== null), // Remove any null entries
    };

    const handleNodeClick = (node: Node) => {
        console.log("Node clicked:", node);
        alert(`Node clicked: ${JSON.stringify(node, null, 2)}`);
    };

    const handleLinkClick = (link: Link) => {
        console.log("Link clicked:", link);
        alert(`Link clicked: ${JSON.stringify(link, null, 2)}`);
    };

    useEffect(() => {
        if (graphRef.current) {
            graphRef.current.d3Force("charge")?.strength(-300); // Adjust force strength dynamically
            // Optionally, adjust other forces or configurations here
        }
    }, []);

    // Optional: Define a generic color palette or strategy
    const getLinkColor = (link: Link): string => {
        // Example: Assign colors based on link type, default to gray
        const typeColorMap: { [key: string]: string } = {
            "BELONGS_TO_SYSTEM": "blue",
            "RELATED_TO": "orange",
            // Add more mappings as needed
        };
        return typeColorMap[link.type] || "gray";
    };

    // Optional: Define a generic width strategy
    const getLinkWidth = (link: Link): number => {
        // Example: Assign width based on link type, default to 1
        const typeWidthMap: { [key: string]: number } = {
            "BELONGS_TO_SYSTEM": 2,
            "RELATED_TO": 1.5,
            // Add more mappings as needed
        };
        return typeWidthMap[link.type] || 1;
    };

    return (
        <ForceGraph2D
            ref={graphRef}
            graphData={graphData}
            nodeLabel={(node: Node) => node.name || node.label || node.id}
            linkLabel={(link: Link) => link.type || link.id}
            onNodeClick={handleNodeClick}
            onLinkClick={handleLinkClick}
            nodeCanvasObject={(node: Node, ctx, globalScale) => {
                const label = node.name || node.label || node.id;
                const fontSize = 12 / globalScale;
                ctx.font = `${fontSize}px Sans-Serif`;
                ctx.fillStyle = "black";
                ctx.fillText(label, (node as any).x + 10, (node as any).y + 5);
            }}
            linkDirectionalArrowLength={5}
            linkDirectionalArrowRelPos={1}
            linkWidth={getLinkWidth} // Use the generic width function
            linkColor={getLinkColor} // Use the generic color function
            // Optional: Add other generic configurations here
        />
    );
};

export default GraphComponent;