import React, { useContext } from 'react';
import { Layout } from 'antd';
import AppHeader from '../components/common/Header';
import AppFooter from '../components/common/Footer';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { LoadingContext } from '../context/LoadingContext';

const { Content } = Layout;

interface GraphLayoutProps {
    children: React.ReactNode;
}

const GraphLayout: React.FC<GraphLayoutProps> = ({ children }) => {
    const { loading } = useContext(LoadingContext);

    return (
        <Layout>
            <AppHeader />
            <Content style={{ padding: '0 50px', marginTop: 64, minHeight: 'calc(100vh - 128px)' }}>
                <div className="graph-layout-content" style={{ background: '#fff', padding: 24 }}>
                    {loading ? <LoadingSpinner /> : children}
                </div>
            </Content>
            <AppFooter />
        </Layout>
    );
};

export default GraphLayout;