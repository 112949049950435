// components/common/NotificationMenu.tsx
import React from 'react';
import { Badge, Dropdown, Menu } from 'antd';
import { BellOutlined } from '@ant-design/icons';

const notifications = [
    { id: 1, message: 'New message received' },
    { id: 2, message: 'Server maintenance scheduled' },
];

const menu = (
    <Menu>
        {notifications.map((notification) => (
            <Menu.Item key={notification.id}>{notification.message}</Menu.Item>
        ))}
    </Menu>
);

const NotificationMenu: React.FC = () => (
    <Dropdown overlay={menu} placement="bottomRight">
        <Badge count={notifications.length} overflowCount={10}>
            <BellOutlined style={{ fontSize: '18px', cursor: 'pointer' }} />
        </Badge>
    </Dropdown>
);

export default NotificationMenu;