// pages/HomePage.tsx
import React from 'react';
import MainLayout from '../layouts/MainLayout';

const HomePage: React.FC = () => {
    return (
        <MainLayout>
            <h1>Home Page</h1>
            {/* Page content */}
        </MainLayout>
    );
};

export default HomePage;