// layouts/MainLayout.tsx
import React, { useContext } from 'react';
import { Layout } from 'antd';
import AppHeader from '../components/common/Header';
import AppFooter from '../components/common/Footer';
import Breadcrumbs from '../components/common/Breadcrumbs';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { LoadingContext } from '../context/LoadingContext';

const { Content } = Layout;

interface MainLayoutProps {
    children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const { loading } = useContext(LoadingContext);

    return (
        <Layout>
            <AppHeader />
            <Content style={{ padding: '0 50px', marginTop: 64 }}>
                <Breadcrumbs />
                <div className="site-layout-content">
                    {loading ? <LoadingSpinner /> : children}
                </div>
            </Content>
            <AppFooter />
        </Layout>
    );
};

export default MainLayout;