import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from 'antd';
import { getMsalConfig } from '../authConfig';
import AuthLayout from '../layouts/AuthLayout';

const LoginPage: React.FC = () => {
    const { instance } = useMsal();

    const handleLogin = async () => {
        try {
            const { loginRequest } = await getMsalConfig();
            await instance.loginPopup(loginRequest);
        } catch (e) {
            console.error('Login failed:', e);
        }
    };

    return (
        <AuthLayout>
            <h1>Login</h1>
            <Button type="primary" onClick={handleLogin}>
                Sign in with Azure
            </Button>
        </AuthLayout>
    );
};

export default LoginPage;