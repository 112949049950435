// pages/SettingsPage.tsx
import React from 'react';
import MainLayout from '../layouts/MainLayout';

const SettingsPage: React.FC = () => (
    <MainLayout>
        <h1>Settings</h1>
        {/* Settings content */}
    </MainLayout>
);

export default SettingsPage;