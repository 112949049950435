// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { getMsalConfig } from './authConfig';
import LoadingSpinner from './components/common/LoadingSpinner';
import './assets/styles/global.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Initially render the LoadingSpinner
root.render(<LoadingSpinner />);

const renderApp = async () => {
    try {
        const {msalConfig} = await getMsalConfig();
        const msalInstance = new PublicClientApplication(msalConfig);

        root.render(
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        );
    } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred.';
        root.render(
            <div>
                <h1>Error initializing application</h1>
                <p>{errorMessage}</p>
            </div>
        );
    }
};

renderApp();